export const environment = {
  production: true,
  backendUrl: "https://pingmoney.co/backend/",
  // backendUrl: "http://admin.pingmoney.prod/",
  // backendUrl: "https://dev.pingmoney.co/backend/",
  apiUrl: "https://pingmoney.co/backend/api/v1",
  // apiUrl: "http://admin.pingmoney.prod/api/v1",
  // apiUrl: "https://dev.pingmoney.co/backend/api/v1",
  appUrl: "https://pingmoney.co/",
  // appUrl: "http://localhost:4200/",
  // appUrl: "https://dev.pingmoney.co/",
  assetUrl: "https://pingmoney.co/assets/",
  // assetUrl: "http://localhost:4200/assets/",
  // assetUrl: "https://dev.pingmoney.co/assets/",
  siteReference: "paythreesixfive73198",
  // siteReference: "test_paythreesixfive73197",
  stActionUrl: "https://payments.securetrading.net/process/payments/details",
  stApiKey: "v7qQXOQIqda8tnszIAJAP33AepLJT8hn",
  acquiredActionUrl: "https://hpp.acquired.com",
  // acquiredCompanyId: "1255",
  // acquiredCompanyId: "9f6e57d9-273f-052f-ba74-518ad3dddaf7", // Todo: to change to this version, once we retire acquired v1
  // acquiredCompanyId: "019016e3-ce45-700f-b2a2-23035da2c40b",
  // acquiredCompanyId: "401",
  acquiredCompanyId: "327",
  acquiredMid: "2096",
  // acquiredMid: "1522",
  templateId: "1182",
  // templateId: "1441",
  acquiredMidEur: "2223",
  // acquiredMidEur: "2214",
  templateIdEur: "1589",
  // templateIdEur: "1723",
  // acquiredCompanyHash: "zCuatbc0",
  acquiredCompanyHash: "kqSuMT9j",
  // acquiredCompanyHash: "LKywyeVf",
  acquiredApiKey: "6p0O7HApp0ArDMAP47is",
  // acquiredApiKey: "7132c76844a1f24c9c049877fe94ae09",
  veriffApiKey: "0a296523-5242-4a88-a790-9f5b7bc113d9",
  applePayMerchantIdentifier: "merchant.co.pingmoney.pay365",
  applePayInitiativeContext: "pingmoney.co",
  applePaydisplayName: "Ping Money",
  initVolumeEnvironment: "LIVE", // LIVE, SANDBOX
  // initVolumeEnvironment: "SANDBOX", // LIVE, SANDBOX
  trueLayerProduction: true, // true, false
};
